import React, { FC, useEffect, useState } from 'react';
import { Page } from '@shared/components/layout/Page';
import { getSharedFileV2, getQueryParamSharedFileV2, searchSharedFileV2, uploadSharedFileV2, deleteSharedFileV2, getClient } from '@shared/fetch';
import { IAppState, IClientInfo, IGraphEntry } from '@shared/types';
import { useSelector } from 'react-redux';
import { Loader } from '@shared/components/loader';
import { Toast } from '@shared/components/toast';
import { Box, Button, Card, CircularProgress, Collapse, Grid, Tooltip } from '@material-ui/core';
import { FileExplorer } from '@src/clients/components/file-explorer';
import { ChevronRight, Close, Save } from '@material-ui/icons';
import { SharedFilesGraphFilters } from './SharedFilesGraphFilters';
import { FileUploader } from '@src/clients/components/file-upload/FileUploader';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { Form, Formik } from 'formik';
import { object, array, mixed } from 'yup';
import { useHistory, useLocation } from 'react-router-dom';
interface ISharedFilesGraph {
  isWidget?: boolean;
  isVertical?: boolean;
}

const BACK_TEXT = '..';

const Schema = object().shape({
  files: array().of(mixed())
});

export const SharedFilesGraph: FC<ISharedFilesGraph> = ({ isWidget, isVertical = true }) => {
  const classes = useStyles();
  const [entries, setEntries] = useState<IGraphEntry[] | null>(null);
  const [isLoading, setIsLoading] = useState(true);
  const { selectedClient } = useSelector((state: IAppState) => state.extranet);

  const location = useLocation();
  const history = useHistory();
  const removeQueryParams = () => {
    history.replace({ search: '' });
  };
  const query = new URLSearchParams(location.search);

  const [fileIdString, setFileIdString] = useState(query.get('fileId'));
  const [{ message: PageMessage, variant: pageVariant, isOpen: pageToastIsOpen }, setPageToast] = useState<{
    message: string;
    variant: 'error' | 'success';
    isOpen: boolean;
  }>({
    message: '',
    variant: 'success',
    isOpen: false
  });

  const formatEntryPath = (entryPath: string[]) => {
    return entryPath.join('/');
  };

  const [entryPath, setEntryPath] = useState<string[]>([]);
  const [searchTerm, setSearchTerm] = useState<string>('');

  const [selectedSort, setSelectedSort] = useState<string>('');
  const [sortDirection, setSortDirection] = useState<{
    Name?: 'Asc' | 'Desc';
    ModifiedDate?: 'Asc' | 'Desc';
  }>({});
  const [clientLoading, setClientLoading] = useState<boolean>(false);
  const [clientDetails, setClientDetails] = useState<IClientInfo | null>(null);

  const getSelectedClient = async (clientId: number) => {
    setClientLoading(true);
    try {
      const clientResponse = await getClient(clientId);
      setClientDetails(clientResponse);
      setClientLoading(false);
    } catch (error) {
      console.error('error', error);
    } finally {
      setClientLoading(false);
    }
  };

  useEffect(() => {
    if (selectedClient) {
      getSelectedClient(selectedClient.clientId);
    }
  }, [selectedClient]);

  const fetchSharedFiles = async () => {
    if (!selectedClient) {
      return;
    }
    setIsLoading(true);

    try {
      let res;

      if (fileIdString) {
        res = await getQueryParamSharedFileV2(selectedClient.clientId, fileIdString);
      } else {
        res = await getSharedFileV2(selectedClient.clientId, formatEntryPath(entryPath), {
          sortBy: selectedSort,
          sortDirection: sortDirection[selectedSort]
        });
      }

      setEntries(res?.filter(entry => entry.name !== BACK_TEXT));
      setSearchTerm('');
      setHasAppliedFilters(false);
    } catch (error) {
      console.error(error);
      setPageToast({
        message: (error as any)?.Detail ?? 'An error occurred while fetching shared files',
        variant: 'error',
        isOpen: true
      });
    } finally {
      setIsLoading(false);
    }
  };

  const searchSharedFiles = async () => {
    if (!selectedClient) {
      return;
    }
    setIsLoading(true);

    try {
      const res = await searchSharedFileV2(selectedClient.clientId, searchTerm);
      setEntries(res?.filter(entry => entry.name !== BACK_TEXT));
      setEntryPath([]);
      setSelectedSort('');
      setSortDirection({});
      setFileIdString('');
      removeQueryParams();
    } catch (error) {
      console.error(error);
      setPageToast({
        message: (error as any)?.Detail ?? 'An error occurred while fetching shared files',
        variant: 'error',
        isOpen: true
      });
    } finally {
      setIsLoading(false);
    }
  };

  const [hasAppliedFilters, setHasAppliedFilters] = useState(false);
  // effects
  useEffect(() => {
    if (!hasAppliedFilters) {
      setEntries(null);
      fetchSharedFiles();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedClient, entryPath, selectedSort, sortDirection]);

  const handleTermSearch = async (clearSearch?: boolean) => {
    try {
      if (searchTerm && searchTerm.length > 0) {
        await searchSharedFiles();
      } else if (clearSearch) {
        setSearchTerm('');
        await fetchSharedFiles();
      }
    } catch (error) {
      console.error(error);
      setPageToast({
        message: (error as any)?.Detail ?? 'An error occurred while fetching shared files',
        variant: 'error',
        isOpen: true
      });
    }
  };

  const [isFileUploaderDisabled, setIsFileUploaderDisabled] = useState<boolean>(false);
  const handleChange = () => {
    setIsFileUploaderDisabled(!isFileUploaderDisabled);
  };

  const [isImportingFiles, setIsImportingFiles] = useState<boolean>(false);

  const [uploadedFiles, setUploadedFiles] = useState<File[]>([]);

  useEffect(() => {
    if (!!searchTerm) {
      setIsImportingFiles(false);
      setIsFileUploaderDisabled(false);
      setUploadedFiles([]);
    }
  }, [searchTerm]);

  const handleDelete = async (e: React.MouseEvent<HTMLButtonElement>, fileId: string) => {
    try {
      if (!selectedClient) {
        return;
      }

      await deleteSharedFileV2(selectedClient.clientId, fileId);
      await fetchSharedFiles();
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <Page
      title='Files V2'
      overflow
      fullMobileWidth
      flexGrow={false}
      hideHeader={true}
      isColumn={false}
      setHeight={false}
      actions={() => {
        return (
          <Tooltip
            title={
              clientDetails && !clientDetails?.folderSharedFiles
                ? 'Please enter a shared files location path under your client details, then you can add files'
                : ''
            }
          >
            <span>
              <Button
                disabled={!selectedClient?.folderSharedFiles || isFileUploaderDisabled || !!searchTerm}
                color='primary'
                variant='contained'
                onClick={handleChange}
              >
                ADD FILES
              </Button>
            </span>
          </Tooltip>
        );
      }}
    >
      <Formik
        enableReinitialize={true}
        initialValues={{
          fileName: '',
          files: []
        }}
        validationSchema={Schema}
        onSubmit={async (values, actions) => {
          try {
            await Promise.all(
              uploadedFiles.map(async (file: File) => {
                return await uploadSharedFileV2(selectedClient?.clientId ?? 0, formatEntryPath(entryPath) ?? '', file);
              })
            );

            setIsImportingFiles(false);
            setIsFileUploaderDisabled(false);
            setUploadedFiles([]);
            actions.resetForm();
            await fetchSharedFiles();
          } catch (e) {
            console.error(e);
            setPageToast({
              message: (e as any)?.Detail ?? 'An error occurred while uploading files',
              variant: 'error',
              isOpen: true
            });
          }
        }}
      >
        {({ resetForm, isSubmitting, values, initialValues, setFieldValue, errors, touched, handleSubmit, dirty, isValid, handleBlur }) => {
          return (
            <>
              {(isLoading || clientLoading) && (
                <Loader position='centered' type='inline'>
                  Loading...
                </Loader>
              )}
              {!isLoading && (
                <>
                  <Collapse in={isFileUploaderDisabled}>
                    <Card className={classes.uploader} elevation={2}>
                      <Form onSubmit={handleSubmit} autoComplete='none'>
                        <Grid container>
                          <Grid container item spacing={1} xs={12}>
                            <Grid item xs={12}>
                              <FileUploader
                                handleFile={async val => {
                                  setUploadedFiles(val);
                                  setFieldValue('files', val);
                                }}
                                handleRemove={(fileToDelete: File) => {
                                  // LastModified is being used here because of it's uniquness
                                  setUploadedFiles(prev => {
                                    const filtered = prev.filter(
                                      file => file.lastModified !== fileToDelete.lastModified && file.name !== fileToDelete.name
                                    );
                                    setFieldValue('files', filtered);
                                    return filtered;
                                  });
                                }}
                                removeText={'Remove'}
                                queuedFiles={uploadedFiles}
                                isRequired={true}
                                dropzoneText='Files to Import'
                              />
                            </Grid>
                          </Grid>
                        </Grid>
                        <Box className={classes.buttonContainer}>
                          <Button
                            disabled={!dirty || isSubmitting || !isValid || values?.files.length < 1}
                            type='submit'
                            startIcon={<Save />}
                            color='secondary'
                            variant='contained'
                          >
                            {isImportingFiles && <CircularProgress size={24} className={classes.progress} />} Upload
                          </Button>
                          <Button
                            startIcon={<Close />}
                            disabled={isImportingFiles}
                            color='secondary'
                            variant='contained'
                            onClick={() => {
                              setUploadedFiles([]);
                              setIsFileUploaderDisabled(false);
                            }}
                          >
                            Cancel
                          </Button>
                        </Box>
                        {isSubmitting && (
                          <Loader position='centered' type='fullscreen'>
                            Uploading Files...
                          </Loader>
                        )}
                      </Form>
                    </Card>
                  </Collapse>
                  <Box style={{ padding: '0 1rem', display: 'flex', alignItems: 'center' }}>
                    <SharedFilesGraphFilters
                      isLoading={isLoading}
                      applyFilters={handleTermSearch}
                      setHasAppliedFilters={setHasAppliedFilters}
                      hasAppliedFilters={hasAppliedFilters}
                      searchTerm={searchTerm}
                      setSearchTerm={setSearchTerm}
                      handleTermSearch={handleTermSearch}
                    />
                  </Box>
                  <Box style={{ padding: '0 1rem', display: 'flex', alignItems: 'center' }}>
                    <Button
                      disabled={entryPath.length === 0 && !fileIdString}
                      variant='text'
                      color='primary'
                      size='large'
                      onClick={() => {
                        setEntryPath([]);
                        setFileIdString('');
                        removeQueryParams();
                      }}
                    >
                      Shared Files
                    </Button>
                    {entryPath.map((path, index) => (
                      <Box style={{ display: 'flex', alignItems: 'center' }} key={index}>
                        <ChevronRight color='primary' />
                        <Button
                          variant='text'
                          color='primary'
                          size='large'
                          disabled={index === entryPath.length - 1}
                          onClick={() => {
                            setEntryPath(prev => prev.slice(0, index + 1));
                          }}
                        >
                          {path}
                        </Button>
                      </Box>
                    ))}
                  </Box>
                </>
              )}
              {!isLoading && (entries?.length ?? 0) === 0 && <Box>No contents.</Box>}
              {!isLoading && (entries?.length ?? 0) > 0 && (
                <>
                  <FileExplorer
                    entries={entries}
                    entryPath={entryPath}
                    setEntryPath={setEntryPath}
                    hasAppliedFilters={hasAppliedFilters}
                    setHasAppliedFilters={setHasAppliedFilters}
                    selectedSort={selectedSort}
                    setSelectedSort={setSelectedSort}
                    sortDirection={sortDirection}
                    setSortDirection={setSortDirection}
                    handleDelete={handleDelete}
                  />
                </>
              )}
              <Toast
                id='page-toast'
                message={PageMessage}
                open={pageToastIsOpen}
                onClose={() =>
                  setPageToast({
                    message: '',
                    variant: pageVariant,
                    isOpen: false
                  })
                }
                variant={pageVariant}
              />
            </>
          );
        }}
      </Formik>
    </Page>
  );
};
const useStyles = makeStyles<Theme>((theme: Theme) => ({
  copyLinkButton: {
    transition: 'background-color 0.3s',
    '&:active': {
      backgroundColor: 'rgba(0, 0, 0, 0.2)'
    }
  },
  uploader: {
    display: 'block',
    padding: theme.spacing(2),
    marginBottom: theme.spacing(1)
  },
  buttonContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: `${theme.spacing(1)}px`,

    [theme.breakpoints.up('md')]: {
      flexDirection: 'row'
    }
  }
}));
