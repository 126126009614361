import React, { FC, useState, useEffect } from 'react';
import { Grid, Typography, useMediaQuery, useTheme, Button, TextField, MenuItem, DialogActions, Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Add } from '@material-ui/icons';
// Fetch, Types
import { assignEmployeeRelease, getEmployeeRoles, getAssignableEmployees, deleteEmployeeFromRelease } from '@shared/fetch';
import { IReleaseEmployeeRole, IAssignableEmployee, IReleaseEmployee } from '@shared/types';
// Context
import { useValidation } from '@src/context/validation-context';
// Components
import { DashboardCard } from '../../../clients/components/DashboardCard';
import { ActionCard } from '../cards/ActionCard';
import { Modal } from '@shared/components/modals/Modal';
import { Toast } from '@shared/components/toast';
import { Loader } from '@shared/components/loader';

interface IAssignedEmployeesProps {
  releaseId: string;
  employeesAssigned: IReleaseEmployee[];
  refetch: () => void;
  isLoading: boolean;
}

export const AssignedEmployees: FC<IAssignedEmployeesProps> = ({ releaseId, employeesAssigned, refetch, isLoading }) => {
  const classes = useStyles();
  const theme = useTheme();
  const isMediumDown = useMediaQuery(theme.breakpoints.down('md'));
  const { setValidationMessages } = useValidation();
  const customPaddingTitle = isMediumDown ? { padding: '1.5rem 1rem 0 1rem' } : { padding: '1rem 1rem 0 1rem' };
  // States //
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedRole, setSelectedRole] = useState<string>('');
  const [selectedEmployee, setSelectedEmployee] = useState<number>(0);
  const [roles, setRoles] = useState<IReleaseEmployeeRole[]>([]);
  const [assignableEmployees, setAssignableEmployees] = useState<IAssignableEmployee[]>([]);
  const [isSaving, setIsSaving] = useState(false);

  const [{ message: PageMessage, variant: pageVariant, isOpen: pageToastIsOpen }, setPageToast] = useState<{
    message: string;
    variant: 'error' | 'success';
    isOpen: boolean;
  }>({
    message: '',
    variant: 'success',
    isOpen: false
  });

  useEffect(() => {
    const fetchRoles = async () => {
      try {
        const rolesResult = await getEmployeeRoles();
        setRoles(rolesResult);
      } catch (error) {
        console.error('Error fetching roles:', error);
        setPageToast({
          message: 'Error fetching roles',
          variant: 'error',
          isOpen: true
        });
      }
    };

    fetchRoles();
  }, []);

  useEffect(() => {
    const fetchAssignableEmployees = async () => {
      try {
        const employeesResult = await getAssignableEmployees();
        setAssignableEmployees(employeesResult);
      } catch (error) {
        console.error('Error fetching assignable employees:', error);
        setPageToast({
          message: 'Error fetching assignable employees',
          variant: 'error',
          isOpen: true
        });
      }
    };

    fetchAssignableEmployees();
  }, []);

  useEffect(() => {
    const errors: string[] = [];
    if (employeesAssigned.length === 0) {
      errors.push('Assigned Employee');
    }
    setValidationMessages(prev => {
      const filteredMessages = prev.filter(msg => !msg.startsWith('Assigned Employee:'));
      return [...filteredMessages, ...errors];
    });
  }, [employeesAssigned, setValidationMessages]);

  const handleOpenModal = () => {
    setSelectedRole('');
    setSelectedEmployee(0);
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const handleSave = async () => {
    try {
      setIsSaving(true);
      await assignEmployeeRelease(Number(releaseId), selectedEmployee, selectedRole);
      setPageToast({
        message: 'Employee assigned',
        variant: 'success',
        isOpen: true
      });
      // refresh the list of assigned employees
      refetch();
    } catch (error) {
      console.error('Error assigning employee:', error);
      setPageToast({
        message: (error as any)?.response.data.Detail ?? 'Error assigning employee',
        variant: 'error',
        isOpen: true
      });
    }
    setSelectedRole('');
    setSelectedEmployee(0);
    setIsSaving(false);
  };

  const handleRemoveEmployee = async (releaseId: number, releaseEmployeeId: number) => {
    try {
      await deleteEmployeeFromRelease(releaseId, releaseEmployeeId);
      setPageToast({
        message: 'Employee removed',
        variant: 'success',
        isOpen: true
      });
      // Refresh the list of assigned employees
      refetch();
    } catch (error) {
      console.error('Error removing employee:', error);
      setPageToast({
        message: 'Error removing employee',
        variant: 'error',
        isOpen: true
      });
    }
  };

  return (
    <>
      <Grid container alignItems='flex-start' justify='space-between' spacing={2} className={classes.cardHolder}>
        <Grid item xs={12}>
          <DashboardCard
            setHeight={false}
            isColumn={false}
            hideTitle={true}
            parentCardPadding='1rem 1rem 0 1rem'
            elevation={6}
            parentCardMarginBottom={isMediumDown ? '0.25rem' : '1rem'}
          >
            {isLoading ? (
              <Loader size='medium' type='overlay' position='centered' />
            ) : employeesAssigned.length > 0 ? (
              <>
                <Grid container spacing={2}>
                  <Grid item xs={12} style={customPaddingTitle}>
                    <Typography className={classes.title}>Employees Assigned to Release</Typography>
                  </Grid>
                  <Grid item xs={5} sm={4}>
                    <Typography className={classes.cardHeader}>Release Role</Typography>
                  </Grid>
                  <Grid item xs={5} sm={4}>
                    <Typography className={classes.cardHeader}>Assigned To</Typography>
                  </Grid>
                  <Grid item xs={2} sm={4} />
                </Grid>
                {employeesAssigned.map(employee => (
                  <Box key={employee.releaseEmployeeId}>
                    <ActionCard
                      contents={[employee.releaseRoleDisplay, employee.employeeName]}
                      onRemove={() => handleRemoveEmployee(employee.releaseId, employee.releaseEmployeeId)}
                      gridSizes={[
                        { xs: 5, sm: 4 },
                        { xs: 5, sm: 4 },
                        { xs: 2, sm: 4 }
                      ]}
                    />
                  </Box>
                ))}
              </>
            ) : (
              <Grid container spacing={2}>
                <Grid item xs={12} style={customPaddingTitle}>
                  <Typography className={classes.title}>Employees Assigned to Release</Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography>No employees added yet, click "Add Employee" to get started.</Typography>
                </Grid>
              </Grid>
            )}
            <Grid container justify='flex-end'>
              <Button
                className={classes.addButton}
                startIcon={<Add />}
                onClick={releaseId && !isNaN(Number(releaseId)) ? handleOpenModal : undefined}
                disabled={!releaseId || isNaN(Number(releaseId))}
              >
                Add Employee
              </Button>
            </Grid>
          </DashboardCard>
        </Grid>
        <Toast
          id='page-toast'
          message={PageMessage}
          open={pageToastIsOpen}
          onClose={() =>
            setPageToast({
              message: '',
              variant: pageVariant,
              isOpen: false
            })
          }
          variant={pageVariant}
        />
      </Grid>
      <Modal maxWidth='sm' open={isModalOpen} onClose={handleCloseModal} title='Add Employee' customPaddingContent='0.5rem 1.5rem 1rem 1.5rem'>
        {isSaving && <Loader type='overlay' position='centered' title='Saving...' />}
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <TextField
              fullWidth
              required
              size='small'
              label='Role'
              variant='outlined'
              select
              value={selectedRole}
              InputLabelProps={{ shrink: true }}
              onChange={e => setSelectedRole(e.target.value)}
              SelectProps={{
                MenuProps: {
                  anchorOrigin: {
                    vertical: 'bottom',
                    horizontal: 'left'
                  },
                  transformOrigin: {
                    vertical: 'top',
                    horizontal: 'left'
                  },
                  getContentAnchorEl: null,
                  PaperProps: {
                    style: {
                      maxHeight: 250,
                      overflowY: 'auto'
                    }
                  }
                }
              }}
            >
              {roles.map(role => (
                <MenuItem key={role.value} value={role.value}>
                  {role.description}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              required
              size='small'
              label='Assign To'
              variant='outlined'
              select
              value={selectedEmployee}
              onChange={e => setSelectedEmployee(Number(e.target.value))}
              SelectProps={{
                MenuProps: {
                  anchorOrigin: {
                    vertical: 'bottom',
                    horizontal: 'left'
                  },
                  transformOrigin: {
                    vertical: 'top',
                    horizontal: 'left'
                  },
                  getContentAnchorEl: null,
                  PaperProps: {
                    style: {
                      maxHeight: 250,
                      overflowY: 'auto'
                    }
                  }
                }
              }}
            >
              {assignableEmployees.map(employee => (
                <MenuItem key={employee.value} value={employee.value}>
                  {employee.description}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
        </Grid>
        <DialogActions className='mt-2'>
          <Button disabled={isSaving} onClick={handleCloseModal} color='primary'>
            Close
          </Button>
          <Button disabled={isSaving || !selectedEmployee || !selectedRole} onClick={handleSave} color='primary' variant='contained'>
            Save
          </Button>
        </DialogActions>
      </Modal>
    </>
  );
};

const useStyles = makeStyles(theme => ({
  title: {
    fontSize: '1.10rem',
    color: '#616161',
    fontWeight: 600,
    [theme.breakpoints.down('sm')]: {
      fontSize: '1rem'
    }
  },
  cardHolder: {
    alignItems: 'stretch'
  },
  cardHeader: {
    fontSize: '1rem',
    [theme.breakpoints.down('sm')]: {
      fontSize: '0.85rem'
    },
    fontWeight: 500,
    marginBottom: '0.25rem'
  },
  addButton: {
    color: theme.palette.primary.main,
    textTransform: 'none',
    fontWeight: 500,
    '& .MuiButton-startIcon': {
      marginRight: theme.spacing(0.25)
    }
  }
}));
