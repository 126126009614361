import { makeStyles, Theme } from '@material-ui/core/styles';
import { ReportProblemOutlined } from '@material-ui/icons';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import Typography from '@material-ui/core/Typography';
import clsx from 'clsx';
import React, { FC, HTMLAttributes, ReactElement } from 'react';
import { Link } from 'react-router-dom';

interface ICustomMessage extends HTMLAttributes<HTMLDivElement> {
  icon?: ReactElement;
  iconClass?: string;
  messages: string[];
  showIcon?: boolean;
  mode: string;
  link?: string;
  linkText?: string;
  textClass?: string;
}

export const CustomMessage: FC<ICustomMessage> = ({ children, mode, icon, messages, link, linkText, showIcon = true, textClass, ...props }) => {
  const classes = emptyStyles();

  return (
    <>
      {mode === 'info' && (
        <div className={clsx(classes.info, props.className)} {...props}>
          {showIcon && <InfoOutlinedIcon className={classes.icon} color='secondary' />}

          {messages.map((message, index) => {
            return (
              <Typography className={clsx(classes.message, textClass)} key={`${index}`} variant='body1'>
                {message} <br />
                {link && (
                  <Link className={classes.linkText} to={link}>
                    {linkText}
                  </Link>
                )}
              </Typography>
            );
          })}
          {children}
        </div>
      )}
      {mode === 'warn' && (
        <div className={clsx(classes.warn, props.className)} {...props}>
          {showIcon && <ReportProblemOutlined className={classes.icon} color='error' />}
          {messages.map((message, index) => {
            return (
              <Typography className={clsx(classes.message, textClass)} key={`${index}`} variant='body1'>
                {message} <br />
                {link && (
                  <Link className={classes.linkText} to={link}>
                    {linkText}
                  </Link>
                )}
              </Typography>
            );
          })}
          {children}
        </div>
      )}
      {mode === 'custom' && (
        <div className={clsx(classes.info, classes.customIcon, props.className)} {...props}>
          {icon}
          {messages.map((message, index) => {
            return (
              <Typography className={clsx(classes.message, textClass)} key={`${index}`} variant='body1'>
                {message}
              </Typography>
            );
          })}
          {children}
        </div>
      )}
    </>
  );
};

const emptyStyles = makeStyles((theme: Theme) => {
  return {
    info: {
      alignItems: 'center',
      backgroundColor: '#f2f3f4',
      border: `1px solid #e9eaeb`,
      borderRadius: theme.shape.borderRadius,
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      padding: theme.spacing(2)
    },
    warn: {
      alignItems: 'center',
      backgroundColor: '#faf0f0',
      border: `1px solid #e9eaeb`,
      borderRadius: theme.shape.borderRadius,
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      padding: theme.spacing(2)
    },
    icon: {
      fontSize: 60,
      marginBottom: theme.spacing(1)
    },
    customIcon: {
      '& > svg': {
        fontSize: 60,
        marginBottom: theme.spacing(1)
      }
    },
    message: {
      fontFamily: 'Poppins-SemiBold, sans-serif',
      fontSize: 18,
      lineHeight: 1.4,
      marginBottom: theme.spacing(0.5),
      textAlign: 'center',
      '&:last-child': {
        marginBottom: theme.spacing(0)
      }
    },
    linkText: {
      color: 'black'
    }
  };
});
