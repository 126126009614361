import React, { useEffect, useState } from 'react';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { TextField, Button, FormControl, Grid, CircularProgress, Typography, MenuItem, Select, InputLabel } from '@material-ui/core';
import { Page } from '@shared/components/layout';
import { Toast } from '@shared/components/toast';
import { getClientZendeskFormTypes, getInquiryTypes, uploadTicketAttachments, createZendeskTicket, getSeverityTags } from '../../shared/fetch';
import { IClientRelationsLookup, IZendeskDropdownLookup, IZendeskTicketInfo } from '@shared/types';
import { Formik, Form, Field } from 'formik';
import { object, mixed, string, date, array } from 'yup';

enum TicketFormId {
  ReportAProblem = 18102344630164,
  GeneralInquiry = 33727,
  NewTaskRequest = 18271720021780,
  RequestAFeature = 18100767307156,
  RequestARelease = 18198004889876,
  UATFinding = 18101381585428
}

const validationSchema = object({
  ticketFormId: mixed().test('is-string-or-number', 'Field must be a string or a number', value => {
    return typeof value === 'string' || typeof value === 'number';
  }),
  department: string().when('ticketFormId', {
    is: TicketFormId.GeneralInquiry,
    then: string().required('Inquiry Type is required'),
    otherwise: string()
  }),
  clientStakeholders: string().when('ticketFormId', {
    is: 'Request a Feature',
    then: string()
  }),
  potentialLaunchDates: string().when('ticketFormId', {
    is: 'Request a Feature',
    then: string()
  }),
  reproductionSteps: string().when('ticketFormId', {
    is: 'UAT Finding',
    then: string()
  }),
  desiredTimeline: date().nullable(),
  application: string(),
  emailAddress: string().email('Invalid email address').required('Email is required'),
  severity: string().when('ticketFormId', {
    is: (val: number) => val === TicketFormId.ReportAProblem || val === TicketFormId.UATFinding,
    then: string().required('Severity is required'),
    otherwise: string()
  }),
  subject: string().required('Subject is required'),
  module: string(),
  description: string().required('Description is required'),
  attachments: array().max(5, 'You can upload up to 5 files').nullable()
});

export const ZendeskForm = () => {
  const classes = useStyles();
  const [isLoadingSeverities, setIsLoadingSeverities] = useState(false);
  const [severities, setSeverities] = useState<IZendeskDropdownLookup[]>([]);
  const [isLoadingFormTypes, setIsLoadingFormTypes] = useState(false);
  const [isLoadingInquiryTypes, setIsLoadingInquiryTypes] = useState(false);
  const [isSuccess, setShowSuccess] = useState<boolean>(false);
  const [isError, setShowError] = useState<boolean>(false);
  const [formTypes, setFormTypes] = useState<IClientRelationsLookup[]>([]);
  const [inquiryTypes, setInquiryTypes] = useState<IZendeskDropdownLookup[]>([]);
  const [files, setFiles] = useState<File[]>([]);
  const [errorMessage, setErrorMessage] = useState<string>('');

  const fetchZendeskSeverities = async () => {
    setIsLoadingSeverities(true);
    try {
      const res = await getSeverityTags();
      setSeverities(res);
    } catch (error) {
      setErrorMessage('Error fetching Zendesk Severity Types');
      console.log(error);
    } finally {
      setIsLoadingSeverities(false);
    }
  };

  const fetchZendeskFormtypes = async () => {
    setIsLoadingFormTypes(true);
    try {
      const res = await getClientZendeskFormTypes();
      setFormTypes(res);
    } catch (error) {
      setErrorMessage('Error fetching Zendesk Form Types');
      console.log(error);
    } finally {
      setIsLoadingFormTypes(false);
    }
  };

  const fetchInquiryTypes = async () => {
    setIsLoadingInquiryTypes(true);
    try {
      const res = await getInquiryTypes();
      setInquiryTypes(res);
    } catch (error) {
      setErrorMessage('Error fetching Zendesk Inquiry Types');
      console.log(error);
    } finally {
      setIsLoadingInquiryTypes(false);
    }
  };

  useEffect(() => {
    fetchZendeskSeverities();
    fetchZendeskFormtypes();
    fetchInquiryTypes();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newFiles = Array.from(event.target.files || []);
    const validFiles = newFiles.filter(file => file.type === 'application/pdf' || file.type.startsWith('image/'));

    if (newFiles.length !== validFiles.length) {
      alert('Only PDF and image files are allowed.');
    }

    if (validFiles.length + files.length > 5) {
      alert('You can upload up to 5 files');
    } else {
      setFiles(prevFiles => [...prevFiles, ...validFiles]);
    }
  };

  function handleTicketFormIdChange(
    event: React.ChangeEvent<HTMLInputElement>,
    setFieldValue: (field: string, value: any) => void,
    validateForm: () => Promise<any>
  ) {
    const newTicketFormId = Number(event.target.value);

    setFieldValue('ticketFormId', newTicketFormId);
    if (newTicketFormId !== TicketFormId.GeneralInquiry) {
      setFieldValue('department', '');
    }

    if (newTicketFormId !== TicketFormId.RequestAFeature) {
      setFieldValue('clientStakeholders', '');
      setFieldValue('potentialLaunchDates', '');
    }

    if (newTicketFormId !== TicketFormId.RequestARelease) {
      setFieldValue('clientStakeholders', '');
      setFieldValue('potentialLaunchDates', '');
    }

    if (newTicketFormId !== TicketFormId.UATFinding) {
      setFieldValue('reproductionSteps', '');
    }

    if (newTicketFormId !== TicketFormId.ReportAProblem && newTicketFormId !== TicketFormId.UATFinding) {
      setFieldValue('severity', '');
    }

    if (
      newTicketFormId !== TicketFormId.ReportAProblem &&
      newTicketFormId !== TicketFormId.RequestAFeature &&
      newTicketFormId !== TicketFormId.UATFinding
    ) {
      setFieldValue('module', '');
    }

    if (
      newTicketFormId !== TicketFormId.RequestAFeature &&
      newTicketFormId !== TicketFormId.RequestARelease &&
      newTicketFormId !== TicketFormId.UATFinding
    ) {
      setFieldValue('application', '');
    }

    // Using a timeout ensures all field updates are completed before calling validate
    setTimeout(() => {
      validateForm();
    }, 0);
  }

  const removeFile = (index: number) => {
    setFiles(prevFiles => prevFiles.filter((_, i) => i !== index));
  };

  //@ts-ignore
  const handleSubmit = async (values: any, { setSubmitting, resetForm }) => {
    try {
      const fileFormData = new FormData();
      files.forEach(file => {
        fileFormData.append('attachments', file);
      });

      const response = await uploadTicketAttachments(fileFormData);

      const data: IZendeskTicketInfo = {
        ...values,
        uploadedFileTokens: response?.value || []
      };

      await createZendeskTicket(data);
      resetForm();
      setFiles([]);
      setShowSuccess(true);
    } catch (error) {
      setShowError(true);
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <Page title='Zendesk Form' overflow={false} flexGrow={false} setHeight={false}>
      <Formik
        initialValues={{
          ticketFormId: '',
          department: '',
          application: '',
          clientStakeholders: '',
          potentialLaunchDates: '',
          reproductionSteps: '',
          name: '',
          emailAddress: '',
          desiredTimeline: null,
          severity: '',
          subject: '',
          module: '',
          description: ''
        }}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {({ isSubmitting, setFieldValue, values, errors, touched, dirty, isValid, validateForm }) => (
          <Form>
            <Grid container spacing={1}>
              <Grid item xs={12} sm={6}>
                <FormControl variant='outlined' fullWidth>
                  <InputLabel required>Select Issue Type</InputLabel>
                  <Field
                    as={Select}
                    name='ticketFormId'
                    label='Select Issue Type'
                    disabled={isLoadingFormTypes}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleTicketFormIdChange(e, setFieldValue, validateForm)}
                    required
                  >
                    {formTypes?.map((form, index) => {
                      const key = `${form.value}-${index}`;
                      return (
                        <MenuItem key={key} value={form.value}>
                          {form.description}
                        </MenuItem>
                      );
                    })}
                  </Field>
                </FormControl>
              </Grid>

              {Number(values.ticketFormId) === TicketFormId.GeneralInquiry && (
                <Grid item xs={12} sm={6}>
                  <FormControl variant='outlined' fullWidth>
                    <InputLabel required>Type of Inquiry</InputLabel>
                    <Field as={Select} name='department' label='Type of Inquiry' disabled={isLoadingInquiryTypes}>
                      {inquiryTypes?.map((inquiry, index) => {
                        const key = `${inquiry.value}-${index}`;
                        return (
                          <MenuItem key={key} value={inquiry.value}>
                            {inquiry.text}
                          </MenuItem>
                        );
                      })}
                    </Field>
                  </FormControl>
                </Grid>
              )}

              <Grid item xs={12} sm={6}>
                <Field as={TextField} name='name' label='Your Name (optional)' variant='outlined' fullWidth />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Field
                  as={TextField}
                  name='emailAddress'
                  label='Email Address'
                  variant='outlined'
                  fullWidth
                  required
                  error={touched.emailAddress && Boolean(errors.emailAddress)}
                  helperText={touched.emailAddress && errors.emailAddress}
                />
              </Grid>
              {Number(values.ticketFormId) !== TicketFormId.RequestAFeature &&
                Number(values.ticketFormId) !== TicketFormId.GeneralInquiry &&
                Number(values.ticketFormId) !== TicketFormId.NewTaskRequest &&
                Number(values.ticketFormId) !== TicketFormId.RequestARelease && (
                  <Grid item xs={12} sm={6}>
                    <FormControl variant='outlined' fullWidth>
                      <InputLabel required>Severity</InputLabel>
                      <Field as={Select} name='severity' label='Severity' disabled={isLoadingSeverities} required>
                        {severities?.map((severity, index) => {
                          const key = `${severity.value}-${index}`;
                          return (
                            <MenuItem key={key} value={severity.value}>
                              {severity.text}
                            </MenuItem>
                          );
                        })}
                      </Field>
                    </FormControl>
                  </Grid>
                )}
              <Grid item xs={12} sm={6}>
                <Field
                  as={TextField}
                  name='subject'
                  label='Subject'
                  variant='outlined'
                  fullWidth
                  required
                  error={touched.subject && Boolean(errors.subject)}
                  helperText={touched.subject && errors.subject}
                />
              </Grid>

              {(Number(values.ticketFormId) === TicketFormId.RequestAFeature ||
                Number(values.ticketFormId) === TicketFormId.RequestARelease ||
                Number(values.ticketFormId) === TicketFormId.UATFinding) && (
                <Grid item xs={12} sm={6}>
                  <Field
                    as={TextField}
                    name='application'
                    label='Application'
                    variant='outlined'
                    fullWidth
                    error={touched.application && Boolean(errors.application)}
                    helperText={touched.application && errors.application}
                  />
                </Grid>
              )}
              {Number(values.ticketFormId) === TicketFormId.RequestARelease && (
                <>
                  <Grid item xs={12} sm={6}>
                    <Field
                      as={TextField}
                      name='clientStakeholders'
                      label='Client Stakeholders'
                      variant='outlined'
                      fullWidth
                      error={touched.clientStakeholders && Boolean(errors.clientStakeholders)}
                      helperText={touched.clientStakeholders && errors.clientStakeholders}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Field
                      as={TextField}
                      name='potentialLaunchDates'
                      label='Potential Launch Dates'
                      variant='outlined'
                      fullWidth
                      error={touched.potentialLaunchDates && Boolean(errors.potentialLaunchDates)}
                      helperText={touched.potentialLaunchDates && errors.potentialLaunchDates}
                    />
                  </Grid>
                </>
              )}
              {(Number(values.ticketFormId) === TicketFormId.ReportAProblem ||
                Number(values.ticketFormId) === TicketFormId.RequestAFeature ||
                Number(values.ticketFormId) === TicketFormId.UATFinding) && (
                <Grid item xs={12} sm={6}>
                  <Field
                    as={TextField}
                    name='module'
                    label='Module'
                    variant='outlined'
                    fullWidth
                    error={touched.module && Boolean(errors.module)}
                    helperText={touched.module && errors.module}
                  />
                </Grid>
              )}

              {/* I did not see this in the help widget, I am removing this for now, but it will likely need to be added at a later date */}
              {/* {Number(values.ticketFormId) === TicketFormId.NewTaskRequest && (
                <Grid item xs={6} className={classes.datePicker}>
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <DatePicker
                      id='desiredTimeline'
                      label='Desired Timeline'
                      value={values?.desiredTimeline}
                      onChange={date => setFieldValue('desiredTimeline', date)}
                      format='MM/dd/yyyy'
                      fullWidth
                      autoOk
                      inputVariant='outlined'
                      error={touched.desiredTimeline && Boolean(errors.desiredTimeline)}
                      helperText={touched.desiredTimeline && errors.desiredTimeline}
                    />
                  </MuiPickersUtilsProvider>
                </Grid>
              )} */}

              <Grid item xs={12}>
                <Field
                  as={TextField}
                  name='description'
                  label='Description'
                  variant='outlined'
                  fullWidth
                  multiline
                  required
                  rows={4}
                  error={touched.description && Boolean(errors.description)}
                  helperText={touched.description && errors.description}
                />
              </Grid>

              {Number(values.ticketFormId) === TicketFormId.UATFinding && (
                <Grid item xs={12}>
                  <Field
                    as={TextField}
                    name='reproductionSteps'
                    label='Reproduction Steps'
                    variant='outlined'
                    fullWidth
                    multiline
                    rows={4}
                    error={touched.reproductionSteps && Boolean(errors.reproductionSteps)}
                    helperText={touched.reproductionSteps && errors.reproductionSteps}
                  />
                </Grid>
              )}
              <Grid item xs={12}>
                <input
                  style={{ display: 'none' }}
                  id='attachments'
                  name='attachments'
                  type='file'
                  accept='image/*,application/pdf'
                  multiple
                  onChange={handleFileChange}
                  disabled={files.length >= 5}
                />
                <label htmlFor='attachments'>
                  <Button variant='contained' color='secondary' component='span' disabled={files.length >= 5}>
                    Attachments
                  </Button>
                </label>
                {files.length >= 5 && <Typography variant='body2'>Maximum of 5 files can be selected</Typography>}
                <Grid container spacing={2} className='mt-1'>
                  {files.map((file, index) => {
                    const key = `${file.name}-${index}`;
                    return (
                      <Grid item key={key}>
                        <div>
                          <Typography variant='body2'>{file.name}</Typography>
                          <Button variant='outlined' color='secondary' onClick={() => removeFile(index)}>
                            Remove
                          </Button>
                        </div>
                      </Grid>
                    );
                  })}
                </Grid>
              </Grid>
              <Grid item xs={12} sm={2} className={classes.submitButton}>
                <Button type='submit' variant='contained' color='primary' disabled={isSubmitting || !isValid || !values.ticketFormId} fullWidth>
                  {isSubmitting ? 'Submitting...' : 'Submit'}
                  {isSubmitting && <CircularProgress size={24} className={classes.progress} />}
                </Button>
              </Grid>
            </Grid>
          </Form>
        )}
      </Formik>
      <Toast
        id='zendesk-ticket-success'
        message='Zendesk Ticket Created!'
        autoHideDuration={6000}
        open={isSuccess}
        onClose={() => setShowSuccess(false)}
        variant='success'
      />
      <Toast
        id='zendesk-ticket-failure'
        message='Error Creating Zendesk Ticket, Please Try Again.'
        autoHideDuration={6000}
        open={isError}
        onClose={() => setShowError(false)}
        variant='error'
      />
      <Toast
        id='lookup-error'
        autoHideDuration={6000}
        message={errorMessage}
        open={!!errorMessage}
        onClose={() => setErrorMessage('')}
        variant='error'
      />
    </Page>
  );
};

const useStyles = makeStyles<Theme>((theme: Theme) => ({
  root: {
    '& .MuiInputBase-root': {
      padding: 0,
      '& .MuiButtonBase-root': {
        padding: '15, 5, 15, 0'
      },
      '& .MuiInputBase-input': {
        paddingTop: 10.5,
        paddingBottom: 10.5
      }
    }
  },
  datePicker: {
    marginTop: '-16px'
  },
  submitButton: {
    marginTop: '12px',
    minWidth: '275px'
  },
  progress: {
    position: 'absolute',
    top: 5,
    left: 25,
    zIndex: 1
  }
}));
