import React, { FC, useState, useEffect, useRef } from 'react';
import { Grid, Typography, useMediaQuery, useTheme, Button, Divider, Box } from '@material-ui/core';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import { Add, SaveOutlined, ArrowBack, Check, Mail } from '@material-ui/icons';
import { useHistory, useParams } from 'react-router-dom';
import { getReleaseContents, updateReleaseContents, getReleaseSuggestions, dismissReleaseSuggestions, getReleaseById } from '@shared/fetch';
import { IReleaseContent, ISuggestionContent } from '@shared/types';
// Context
import { useValidation } from '@src/context/validation-context';
// Components
import { ActionButtons } from '@src/employees/components/action-buttons';
import { DashboardCard } from '../../../clients/components/DashboardCard';
import { Toast } from '@shared/components/toast';
import { Loader } from '@shared/components/loader';
import { DragAndDrop } from '@shared/components/drag-and-drop';
import { ReleaseSuggestionsModal } from '../modals/ReleaseSuggestionsModal';
import { RefetchOptions, RefetchQueryFilters, QueryObserverResult } from 'react-query';

interface IReleaseContentsProps {
  canDeliveryLeadSave?: boolean;
  handleDeliveryLeadApprove?: () => void;
  handleClientApproved?: () => void;
  refetchCanDeliveryLeadSave: <TPageData>(
    options?: (RefetchOptions & RefetchQueryFilters<TPageData>) | undefined
  ) => Promise<QueryObserverResult<boolean, any>>;
  contactsAssigned: boolean;
  releaseDate: Date | null;
  selectedReleaseTime: string;
  releaseStatusName: string;
  clientApprovalEmailHasSent: boolean;
  hasEMOrDLAssigned: boolean;
  hasPendingChanges: boolean;
  setHasPendingChanges: (hasPendingChanges: boolean) => void;
}

export const ReleaseContents: FC<IReleaseContentsProps> = ({
  canDeliveryLeadSave,
  handleDeliveryLeadApprove,
  handleClientApproved,
  refetchCanDeliveryLeadSave,
  contactsAssigned,
  releaseDate,
  selectedReleaseTime,
  releaseStatusName,
  clientApprovalEmailHasSent,
  hasEMOrDLAssigned,
  hasPendingChanges,
  setHasPendingChanges
}) => {
  const classes = useStyles();
  const theme = useTheme();
  const history = useHistory();
  const isMediumDown = useMediaQuery(theme.breakpoints.down('sm'));
  const { setPageKey, setValidationMessages } = useValidation();
  // States //
  const [releaseContents, setReleaseContents] = useState<IReleaseContent[]>([]);
  const [areContentsSaved, setAreContentsSaved] = useState(true);
  const [isLoading, setIsLoading] = useState(true);
  const [isLoadingModalData, setIsLoadingModalData] = useState(true);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [suggestions, setSuggestions] = useState<ISuggestionContent[]>([]);
  const [dismissedTfsIds, setDismissedTfsIds] = useState<number[]>([]);
  const [modalSelectedSuggestions, setModalSelectedSuggestions] = useState<ISuggestionContent[]>([]);
  const [modalDismissedTfsIds, setModalDismissedTfsIds] = useState<number[]>([]);
  const [clientId, setClientId] = useState<number | null>(null);
  const [isSaving, setIsSaving] = useState(false);
  const tempIdCounter = useRef(1); // Counter for temporary IDs

  const { releaseId }: { releaseId: string } = useParams();

  const [{ message: PageMessage, variant: pageVariant, isOpen: pageToastIsOpen }, setPageToast] = useState<{
    message: string;
    variant: 'error' | 'success';
    isOpen: boolean;
  }>({
    message: '',
    variant: 'success',
    isOpen: false
  });

  useEffect(() => {
    setPageKey('releasePage');
  }, [setPageKey]);

  useEffect(() => {
    const fetchReleaseContents = async () => {
      try {
        const releaseContentsResult = await getReleaseContents(Number(releaseId));
        const releaseDataResult = await getReleaseById(Number(releaseId));
        setClientId(releaseDataResult.clientId);
        setReleaseContents(releaseContentsResult);
        setIsLoading(false);
      } catch (error) {
        console.error('Error fetching release contents:', error);
        setIsLoading(false);
      }
    };
    fetchReleaseContents();
  }, [releaseId]);

  useEffect(() => {
    const errors: string[] = [];
    if (releaseContents.length === 0 || !areContentsSaved) {
      errors.push('Release Contents');
    }
    setValidationMessages(prev => {
      const filteredMessages = prev.filter(msg => !msg.startsWith('Release Contents'));
      return [...filteredMessages, ...errors];
    });
  }, [releaseContents, areContentsSaved, setValidationMessages]);

  const handleAddItem = () => {
    const newItem: IReleaseContent = {
      releaseContentId: -tempIdCounter.current++, // Assign a temporary unique ID
      tfsId: null,
      title: '',
      contentType: '',
      isSilent: false,
      isDeleted: false,
      sprintName: ''
    };
    setReleaseContents([...releaseContents, newItem]);
    setHasPendingChanges(true);
    setAreContentsSaved(false);
  };

  const handleInputChange = (index: number, field: keyof IReleaseContent, value: any) => {
    const updatedContents = [...releaseContents];
    (updatedContents[index][field] as any) = value;
    setReleaseContents(updatedContents);
    setHasPendingChanges(true);
    setAreContentsSaved(false);
  };

  const handleDelete = (index: number) => {
    // Clone the releaseContents array
    const updatedContents = [...releaseContents];
    // Remove the item at the specified index
    const removedItem = updatedContents.splice(index, 1)[0];
    // If the item exists, mark it as deleted
    if (removedItem) {
      removedItem.isDeleted = true; // Flag this item for deletion
      updatedContents.push(removedItem); // Re-add the item with the isDeleted flag
      // Update the state with the modified array
      setReleaseContents(updatedContents);
      setHasPendingChanges(true);
      setAreContentsSaved(false);
      // Add removed item back to the suggestions list if it was initially a suggestion
      if (removedItem.tfsId !== null) {
        const suggestionItem: ISuggestionContent = {
          tfsId: removedItem.tfsId,
          title: removedItem.title || '',
          contentType: removedItem.contentType || '',
          sprintName: removedItem.sprintName || ''
        };
        if (!suggestions.some(suggestion => suggestion.tfsId === suggestionItem.tfsId)) {
          setSuggestions(prevSuggestions => [...prevSuggestions, suggestionItem]);
        }
        setDismissedTfsIds(prevIds => [...prevIds, removedItem.tfsId].filter((id): id is number => id !== null));
        setModalSelectedSuggestions(modalSelectedSuggestions.filter(item => item.tfsId !== removedItem.tfsId));
        setModalDismissedTfsIds(modalDismissedTfsIds.filter(id => id !== removedItem.tfsId));
      }
    }
  };

  const handleSaveUpdate = async (newReleaseContents?: IReleaseContent[]) => {
    try {
      setIsSaving(true);
      const contentsToSave = newReleaseContents || releaseContents;
      const mappedContents = contentsToSave.map(content => ({
        ...content,
        releaseContentId: content.releaseContentId < 0 ? 0 : content.releaseContentId, // Ensure releaseContentId is 0 for new items
        isDeleted: content.isDeleted || false // Keep isDeleted true if it was set
      }));
      await updateReleaseContents(Number(releaseId), mappedContents);

      if (dismissedTfsIds.length > 0) {
        await dismissReleaseSuggestions(Number(releaseId), dismissedTfsIds);
      }
      const updatedContents = await getReleaseContents(Number(releaseId));
      setReleaseContents(updatedContents);
      setPageToast({
        message: 'Release contents updated successfully',
        variant: 'success',
        isOpen: true
      });
      setHasPendingChanges(false);
      setAreContentsSaved(true);
      setModalSelectedSuggestions([]);
      setModalDismissedTfsIds([]);
      setDismissedTfsIds([]);
      // Refetch suggestions after saving
      fetchSuggestions();
      refetchCanDeliveryLeadSave();
    } catch (error) {
      console.error('Error updating release contents:', error);
      setPageToast({
        message: 'Error updating release contents',
        variant: 'error',
        isOpen: true
      });
    } finally {
      setIsSaving(false);
    }
  };

  // modal functions start here //

  const fetchSuggestions = async () => {
    setIsLoadingModalData(true);
    try {
      const suggestionsData = await getReleaseSuggestions(Number(releaseId));
      setSuggestions(suggestionsData);
      setIsLoadingModalData(false);
    } catch (error) {
      console.error('Error fetching suggestions:', error);
      setIsLoadingModalData(false);
    }
  };

  const handleCheckboxChange = (suggestion: ISuggestionContent, checked: boolean) => {
    if (checked) {
      setModalDismissedTfsIds(prevIds => prevIds.filter(id => id !== suggestion.tfsId)); // Uncheck dismiss
      if (!modalSelectedSuggestions.some(item => item.tfsId === suggestion.tfsId)) {
        setModalSelectedSuggestions([...modalSelectedSuggestions, suggestion]);
      }
    } else {
      setModalSelectedSuggestions(modalSelectedSuggestions.filter(item => item.tfsId !== suggestion.tfsId));
    }
  };

  const handleDismissCheckboxChange = (suggestion: ISuggestionContent, checked: boolean) => {
    if (checked) {
      setModalSelectedSuggestions(modalSelectedSuggestions.filter(item => item.tfsId !== suggestion.tfsId)); // Uncheck add
      setModalDismissedTfsIds(prevIds => [...prevIds, suggestion.tfsId]);
    } else {
      setModalDismissedTfsIds(prevIds => prevIds.filter(id => id !== suggestion.tfsId));
    }
  };

  const handleSuggestionInputChange = (index: number, field: keyof ISuggestionContent, value: any) => {
    const updatedSuggestions = [...suggestions];
    (updatedSuggestions[index][field] as any) = value;
    setSuggestions(updatedSuggestions);
  };

  const handleOpenModal = () => {
    setIsModalOpen(true);
    // Use a timeout to delay the fetch until the next event loop, ensuring the modal is open
    setTimeout(() => {
      if (suggestions.length === 0) {
        fetchSuggestions(); // Fetch suggestions only if they are not already fetched
      } else {
        setIsLoadingModalData(false); // Ensure loading state is false if suggestions are already available
      }
    }, 0); // Ensure the modal is fully opened before fetching
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const handleApplyChanges = () => {
    const newItems = modalSelectedSuggestions.map(suggestion => ({
      releaseContentId: -tempIdCounter.current++, // Assign a temporary unique ID
      tfsId: suggestion.tfsId,
      title: suggestion.title,
      contentType: suggestion.contentType,
      isSilent: false,
      isDeleted: false,
      sprintName: suggestion.sprintName
    }));
    const uniqueNewItems = newItems.filter(newItem => !releaseContents.some(prevItem => prevItem.tfsId === newItem.tfsId));

    const updatedContents = [...releaseContents, ...uniqueNewItems];
    handleSaveUpdate(updatedContents);
    setIsModalOpen(false);
  };

  const filteredSuggestions = suggestions.filter(suggestion => !releaseContents.some(content => content.tfsId === suggestion.tfsId));

  return (
    <>
      <Grid container direction='column' spacing={2}>
        <Grid item xs={12}>
          <ActionButtons
            onCancelIcon={<ArrowBack />}
            onCancelText='Cancel'
            onCancel={() => {
              history.push('/employees/software-releases');
            }}
            saveButtonColor={theme.palette.success.main}
            onSave={handleDeliveryLeadApprove}
            onClientApproved={handleClientApproved}
            onSaveText={clientApprovalEmailHasSent ? 'Send Update' : 'Delivery Lead Approve'}
            onSaveIcon={clientApprovalEmailHasSent ? <Mail /> : <Check />}
            disableSave={
              !canDeliveryLeadSave || !areContentsSaved || releaseContents.length === 0 || !releaseDate || !selectedReleaseTime || !hasEMOrDLAssigned
            }
            contactsAssigned={contactsAssigned}
            releaseDate={releaseDate}
            selectedReleaseTime={selectedReleaseTime}
            releaseStatusName={releaseStatusName}
            hasEMOrDLAssigned={hasEMOrDLAssigned}
          />
        </Grid>
      </Grid>
      <Grid container alignItems='flex-start' justify='space-between' spacing={2} className={classes.cardHolder}>
        <Grid item xs={12}>
          <DashboardCard setHeight={false} isColumn={false} hideTitle={true}>
            <Box position='relative'>
              {isLoading || isSaving ? (
                <Loader size='medium' type='overlay' position='centered' />
              ) : releaseContents.filter(content => !content.isDeleted).length > 0 ? ( // Exclude deleted items from rendering
                <>
                  <Grid container spacing={1}>
                    <Grid item xs={12}>
                      <Typography className={classes.title}>Contents</Typography>
                    </Grid>
                    {!isMediumDown && (
                      <>
                        <Grid item xs={3}>
                          <Typography className={clsx(classes.cardHeader, classes.headerMargin)}>Title</Typography>
                        </Grid>
                        <Grid item xs={2}>
                          <Typography className={clsx(classes.cardHeader, classes.headerMargin)}>Story</Typography>
                        </Grid>
                        <Grid item xs={3}>
                          <Typography className={clsx(classes.cardHeader, classes.headerMargin)}>Sprint</Typography>
                        </Grid>
                        <Grid item xs={2}>
                          <Typography className={clsx(classes.cardHeader, classes.headerMarginSmall)}>Type</Typography>
                        </Grid>
                        <Grid item xs={2}>
                          <Typography className={clsx(classes.cardHeader)}>Silent?</Typography>
                        </Grid>
                      </>
                    )}
                  </Grid>
                  <DragAndDrop
                    items={releaseContents.filter(content => !content.isDeleted)} // Pass filtered items here as well
                    handleInputChange={handleInputChange}
                    handleDelete={handleDelete}
                    setReleaseContents={setReleaseContents}
                    clientId={clientId}
                  />
                </>
              ) : (
                <Grid container spacing={1}>
                  <Grid item xs={12}>
                    <Typography className={classes.title}>Contents</Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography>No items added yet, click "Add Item" to get started.</Typography>
                  </Grid>
                </Grid>
              )}
              <Grid container justify='flex-end' className={classes.buttonContainer}>
                <Button className={classes.addButton} startIcon={<Add />} onClick={handleAddItem} disabled={!releaseId || isNaN(Number(releaseId))}>
                  Add New Item
                </Button>
                <Button className={classes.addButton} startIcon={<Add />} onClick={handleOpenModal} disabled={!releaseId || isNaN(Number(releaseId))}>
                  Add Contents
                </Button>
              </Grid>
              <Divider className='customHr' />
              <Grid container justify='flex-end'>
                <Button
                  className={classes.saveButton}
                  startIcon={<SaveOutlined />}
                  onClick={() => handleSaveUpdate()}
                  disabled={!hasPendingChanges || !releaseId || isNaN(Number(releaseId))}
                >
                  Save
                </Button>
              </Grid>
            </Box>
          </DashboardCard>
        </Grid>
        <Toast
          id='page-toast'
          message={PageMessage}
          open={pageToastIsOpen}
          onClose={() =>
            setPageToast({
              message: '',
              variant: pageVariant,
              isOpen: false
            })
          }
          variant={pageVariant}
        />
      </Grid>
      <ReleaseSuggestionsModal
        isModalOpen={isModalOpen}
        handleCloseModal={handleCloseModal}
        suggestions={filteredSuggestions}
        modalSelectedSuggestions={modalSelectedSuggestions}
        modalDismissedTfsIds={modalDismissedTfsIds}
        handleCheckboxChange={handleCheckboxChange}
        handleDismissCheckboxChange={handleDismissCheckboxChange}
        handleSuggestionInputChange={handleSuggestionInputChange}
        handleApplyChanges={handleApplyChanges}
        isMediumDown={isMediumDown}
        isLoading={isLoadingModalData}
        clientId={clientId}
      />
    </>
  );
};

const useStyles = makeStyles(theme => ({
  title: {
    fontSize: '1.125rem',
    color: '#616161',
    fontWeight: 600,
    [theme.breakpoints.down('sm')]: {
      fontSize: '1rem',
      padding: '0.75rem 0 0.5rem 0'
    }
  },
  cardHolder: {
    alignItems: 'stretch',
    marginBottom: '0.5rem'
  },
  cardHeader: {
    fontSize: '1rem',
    fontWeight: 600
  },
  headerMargin: {
    marginLeft: '1.25rem'
  },
  headerMarginSmall: {
    marginLeft: '.5rem'
  },
  checkbox: {
    margin: theme.spacing(1)
  },
  addButton: {
    color: theme.palette.primary.main,
    textTransform: 'none',
    fontWeight: 500,
    '& .MuiButton-startIcon': {
      marginRight: theme.spacing(0.25)
    }
  },
  saveButton: {
    color: theme.palette.success.main,
    textTransform: 'none',
    '& .MuiButton-startIcon': {
      marginRight: theme.spacing(0.5)
    }
  },
  customHr: {
    border: '1px solid #dfe0e1'
  },
  input: {
    flex: 1,
    '& .MuiInputBase-root': {},
    '& .MuiInput-underline:before': {
      borderBottom: 'none'
    },
    '& .MuiInput-underline:hover:before': {
      borderBottom: 'none'
    },
    '& .MuiInput-underline:after': {
      borderBottom: 'none'
    },
    '& .MuiInputBase-input:focus': {
      backgroundColor: 'rgba(255, 255, 255, 0.9)'
    }
  },
  buttonContainer: {
    paddingTop: theme.spacing(0.5),
    [theme.breakpoints.down('sm')]: {
      paddingTop: theme.spacing(1)
    }
  }
}));
