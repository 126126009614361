import { authFetch } from './';

// Types
import {
  IClientAppLookup,
  IClientLookup,
  IReleaseRequest,
  IClientContactsLookup,
  IReleaseEmployee,
  IReleaseEmployeeRole,
  IAssignableEmployee,
  IRelease,
  IReleaseContent,
  IPutReleaseContentsResponse,
  ISuggestionContent,
  IPutSuggestionContentsResponse,
  IReleaseLengths
} from '@shared/types';

/**
 * Create a release
 * @param releaseData IReleaseRequest
 * @returns {Promise<any>} any
 */
export const createRelease = async (releaseData: IReleaseRequest): Promise<IReleaseRequest> => {
  try {
    const { data } = await authFetch.post('/api/LaborTracking/Employees/releases', releaseData);
    return data; // the entire response is returned
  } catch (error) {
    return Promise.reject(error);
  }
};

/**
 * Update a release
 * @param releaseId number
 * @param releaseData IReleaseRequest
 * @returns {Promise<number>} number
 */
export const updateRelease = async (releaseId: number, releaseData: IReleaseRequest): Promise<number> => {
  try {
    const { data } = await authFetch.put(`/api/LaborTracking/Employees/releases/${releaseId}`, releaseData);
    return Promise.resolve(data);
  } catch (error) {
    return Promise.reject(error);
  }
};

/**
 * Update release start and end dates
 * @param releaseId - The ID of the release to update
 * @param releaseData - An object containing the start and end dates
 * @param releaseData.startDate - The start date
 * @param releaseData.endDate - The end date
 */
export const updateReleaseDates = async (releaseId: number, releaseData: { startDate: string; endDate: string }): Promise<number> => {
  try {
    const { data } = await authFetch.put(`/api/LaborTracking/Employees/releases/${releaseId}/release-dates`, releaseData);
    return Promise.resolve(data);
  } catch (error) {
    return Promise.reject(error);
  }
};

/**
 * Get a specific release by its ID
 * @param releaseId number
 * @returns {Promise<IRelease>} IRelease
 */
export const getReleaseById = async (releaseId: number): Promise<IRelease> => {
  try {
    const { data } = await authFetch.get(`/api/LaborTracking/Employees/releases/${releaseId}`);
    return data;
  } catch (error) {
    return Promise.reject(error);
  }
};

/**
 * Get all current releases
 * @param filters object
 * @returns {Promise<any>} any
 */
export const getAllReleases = async (filters?: {
  title?: string;
  statusId?: number | string;
  sortBy?: string;
  sortDirection?: string;
  page?: number;
  perPage?: number | string;
  searchTerm?: string;
  clientId?: number;
}): Promise<any> => {
  try {
    const { data } = await authFetch.get(`/api/LaborTracking/Employees/releases`, {
      params: filters,
      paramsSerializer: (params: any) => {
        return Object.entries(params)
          .flatMap(([key, value]) => {
            if (value === undefined) return [];

            if (Array.isArray(value)) {
              return value.map(v => (v !== undefined ? `${key}=${encodeURIComponent(String(v))}` : []));
            } else {
              return `${key}=${encodeURIComponent(String(value))}`;
            }
          })
          .join('&');
      }
    });
    return Promise.resolve(data);
  } catch (error) {
    return Promise.reject(error);
  }
};

/**
 * Get selected client's current applications
 * @param clientId number
 * @returns {Promise<IClientAppLookup[]>} IClientAppLookup[]
 */
export const getClientAppLookup = async (clientId: number): Promise<IClientAppLookup[]> => {
  try {
    const { data } = await authFetch.get(`/api/LaborTracking/Clients/ClientAppsLookup?ClientId=${clientId}`);
    return data;
  } catch (error) {
    return Promise.reject(error);
  }
};

/**
 * Get all clients, isActive optional
 * @param isActive boolean
 * @returns {Promise<IClientLookup[]>} IClientLookup[]
 */
export const getClientLookup = async (isActive: boolean): Promise<IClientLookup[]> => {
  try {
    const { data } = await authFetch.get(`/api/LaborTracking/Clients/ClientsLookup?isActive=${isActive}`);
    return data;
  } catch (error) {
    return Promise.reject(error);
  }
};

/**
 * Get client contacts, must have client id, optional display name
 * @param clientId number
 * @param displayName string?
 * @returns {Promise<IClientContactsLookup[]>} IClientContactsLookup[]
 */
export const getClientContactsLookup = async (clientId: number, displayName?: string): Promise<IClientContactsLookup[]> => {
  let query = '';
  if (displayName) {
    query += `?DisplayName=${displayName}`;
  }
  try {
    const { data } = await authFetch.get(`/api/LaborTracking/Clients/${clientId}/ClientContactsLookup${query}`);
    return data;
  } catch (error) {
    console.error('Error fetching client contacts lookup:', error);
    return Promise.reject(error);
  }
};

/**
 * Adds contact to release
 * @param releaseId number
 * @param name string
 * @param email string
 * @returns {Promise<any>} any
 */
export const assignContactRelease = async (releaseId: number, name: string, email: string): Promise<any> => {
  try {
    const { data } = await authFetch.post(`/api/LaborTracking/Employees/releases/${releaseId}/contacts`, {
      name,
      email
    });
    return Promise.resolve(data);
  } catch (error) {
    return Promise.reject(error);
  }
};

/**
 * Get contacts assigned to release
 * @param releaseId number
 * @returns {Promise<any[]>} any[]
 */
export const getAssignedContacts = async (releaseId: number): Promise<any[]> => {
  try {
    const { data } = await authFetch.get(`/api/LaborTracking/Employees/releases/${releaseId}/contacts`);
    return data;
  } catch (error) {
    return Promise.reject(error);
  }
};

/**
 * Get employees assigned to release
 * @param releaseId number
 * @returns {Promise<IReleaseEmployee[]>} IReleaseEmployee[]
 */
export const getAssignedEmployees = async (releaseId: number): Promise<IReleaseEmployee[]> => {
  try {
    const { data } = await authFetch.get(`/api/LaborTracking/Employees/releases/${releaseId}/employees`);
    return data;
  } catch (error) {
    return Promise.reject(error);
  }
};

/**
 * Adds employee to release
 * @param releaseId number
 * @param employeeId number
 * @param releaseRole string
 * @returns {Promise<any>} any
 */
export const assignEmployeeRelease = async (releaseId: number, employeeId: number, releaseRole: string): Promise<any> => {
  try {
    const { data } = await authFetch.post(`/api/LaborTracking/Employees/releases/${releaseId}/employees`, {
      employeeId,
      releaseRole
    });
    return Promise.resolve(data);
  } catch (error) {
    return Promise.reject(error);
  }
};

/**
 * Get all roles that can be assigned to an employee
 * @returns {Promise<IReleaseEmployeeRole[]>} IReleaseEmployeeRole[]
 */
export const getEmployeeRoles = async (): Promise<IReleaseEmployeeRole[]> => {
  try {
    const { data } = await authFetch.get('api/LaborTracking/Employees/releases/ReleaseRoles');
    return Promise.resolve(data);
  } catch (error) {
    return Promise.reject(error);
  }
};

/**
 * Get all employees that can be assigned to a release
 * @returns {Promise<IAssignableEmployee[]>} IAssignableEmployee[]
 */
export const getAssignableEmployees = async (): Promise<IAssignableEmployee[]> => {
  try {
    const { data } = await authFetch.get('/api/LaborTracking/Employees/lookup');
    return Promise.resolve(data);
  } catch (error) {
    return Promise.reject(error);
  }
};

/**
 * Delete an employee from a release
 * @param releaseId number
 * @param releaseEmployeeId number
 * @returns {Promise<boolean>} boolean
 */
export const deleteEmployeeFromRelease = async (releaseId: number, releaseEmployeeId: number): Promise<boolean> => {
  try {
    await authFetch.delete(`/api/LaborTracking/Employees/releases/${releaseId}/employees/${releaseEmployeeId}`);
    return Promise.resolve(true);
  } catch (error) {
    console.error('deleteEmployeeFromRelease', error);
    return Promise.reject(error);
  }
};

/**
 * Delete a contact from a release
 * @param releaseId number
 * @param releaseContactId number
 * @returns {Promise<boolean>} boolean
 */
export const deleteContactFromRelease = async (releaseId: number, releaseContactId: number): Promise<boolean> => {
  try {
    await authFetch.delete(`/api/LaborTracking/Employees/releases/${releaseId}/contacts/${releaseContactId}`);
    return Promise.resolve(true);
  } catch (error) {
    console.error('deleteContactFromRelease', error);
    return Promise.reject(error);
  }
};

/**
 * Get the contents of a release
 * @param {number} releaseId - The ID of the release
 * @returns {Promise<IReleaseContent[]>} IReleaseContent[]
 */
export const getReleaseContents = async (releaseId: number): Promise<IReleaseContent[]> => {
  try {
    const { data } = await authFetch.get(`/api/LaborTracking/Employees/releases/${releaseId}/contents`);
    return Promise.resolve(data);
  } catch (error) {
    return Promise.reject(error);
  }
};

/**
 * Update release contents
 * @param releaseId number
 * @param releaseContents IReleaseContent[]
 * @returns {Promise<IPutReleaseContentsResponse>} IPutReleaseContentsResponse
 */
export const updateReleaseContents = async (releaseId: number, releaseContents: IReleaseContent[]): Promise<IPutReleaseContentsResponse> => {
  try {
    const { data } = await authFetch.put(`/api/LaborTracking/Employees/releases/${releaseId}/contents`, { releaseContents });
    return Promise.resolve(data);
  } catch (error) {
    return Promise.reject(error);
  }
};

/**
 * Get the contents of a release suggestions
 * @param {number} releaseId - The ID of the release
 * @returns {Promise<ISuggestionContent[]>} ISuggestionContent[]
 */
export const getReleaseSuggestions = async (releaseId: number): Promise<ISuggestionContent[]> => {
  try {
    const { data } = await authFetch.get(`/api/LaborTracking/Employees/releases/${releaseId}/contents/suggestions`);
    return Promise.resolve(data);
  } catch (error) {
    return Promise.reject(error);
  }
};

/**
 * Dismiss release content suggestions
 * @param {number} releaseId - The ID of the release
 * @param {number[]} tfsIds - The TFS IDs to dismiss
 * @returns {Promise<IPutSuggestionContentsResponse>} IPutSuggestionContentsResponse
 */
export const dismissReleaseSuggestions = async (releaseId: number, tfsIds: number[]): Promise<IPutSuggestionContentsResponse> => {
  try {
    const { data } = await authFetch.put(`/api/LaborTracking/Employees/releases/${releaseId}/contents/suggestions/dismiss`, { tfsIds });
    return Promise.resolve(data);
  } catch (error) {
    return Promise.reject(error);
  }
};

/**
 * Delete a software release
 * @param releaseId number
 * @returns {Promise<boolean>} boolean
 */
export const deleteSoftwareRelease = async (releaseId: number): Promise<boolean> => {
  try {
    await authFetch.delete(`/api/LaborTracking/Employees/releases/${releaseId}`);
    return Promise.resolve(true);
  } catch (error) {
    console.error('deleteSoftwareRelease', error);
    return Promise.reject(error);
  }
};

/**
 * Can Exit Software Release
 * @param releaseId string
 * @returns {Promise<boolean>} boolean
 */
export const canExitSoftwareRelease = async (releaseId: string): Promise<boolean> => {
  try {
    const { data } = await authFetch.get(`/api/LaborTracking/Employees/releases/${releaseId}/can-exit-planning`);
    return Promise.resolve(data);
  } catch (error) {
    console.error('canExitSoftwareRelease', error);
    return Promise.reject(error);
  }
};

/**
 * Exit Software Delete
 * @param releaseId string
 * @returns {Promise<null>} boolean
 */
export const exitSoftwareRelease = async (releaseId: string): Promise<boolean> => {
  try {
    const { data } = await authFetch.put(`/api/LaborTracking/Employees/releases/${releaseId}/exit-planning`, {});
    return Promise.resolve(data);
  } catch (error) {
    console.error('exitSoftwareRelease', error);
    return Promise.reject(error);
  }
};

/**
 * Approve Software Release
 * @param releaseId string
 * @returns {Promise<any>} any
 */
export const approveSoftwareRelease = async (releaseId: string): Promise<any> => {
  try {
    const { data } = await authFetch.put(`/api/LaborTracking/Employees/releases/${releaseId}/approve`, {});
    return Promise.resolve(data);
  } catch (error) {
    console.error('approveSoftwareRelease', error);
    return Promise.reject(error);
  }
};

/**
 * Begin Software Release
 * @param releaseId string
 * @returns {Promise<any>} any
 */
export const beginSoftwareRelease = async (releaseId: string): Promise<any> => {
  try {
    const { data } = await authFetch.put(`/api/LaborTracking/Employees/releases/${releaseId}/begin-release`, {});
    return Promise.resolve(data);
  } catch (error) {
    console.error('beginSoftwareRelease', error);
    return Promise.reject(error);
  }
};

/**
 * Mark Release As Verifying
 * @param releaseId string
 * @returns {Promise<null>} boolean
 */
export const markReleaseAsVerifying = async (releaseId: string): Promise<boolean> => {
  try {
    const { data } = await authFetch.post(`/api/LaborTracking/Employees/releases/${releaseId}/verify`, {});
    return Promise.resolve(data);
  } catch (error) {
    return Promise.reject(error);
  }
};

/**
 * Complete Software Release
 * @param releaseId number
 * @param endDate string
 * @returns {Promise<any>} any
 */
export const completeSoftwareRelease = async (releaseId: string, endDate: string): Promise<any> => {
  try {
    const { data } = await authFetch.post(`/api/LaborTracking/Employees/releases/${releaseId}/complete`, {
      completedTime: endDate
    });
    return Promise.resolve(data);
  } catch (error) {
    return Promise.reject(error);
  }
};

/**
 * GET release lengths
 * @returns {Promise<any>} any
 */
export const getReleaseLengths = async (): Promise<IReleaseLengths[]> => {
  try {
    const { data } = await authFetch.get(`/api/LaborTracking/Employees/release-lengths`);
    return Promise.resolve(data);
  } catch (error) {
    return Promise.reject(error);
  }
};
